import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import globalData from "./config.json";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      event: [],
      token: "",
    };
  }
  componentDidMount() {
    // console.log("componentDidUpdateaa footer");
    this.getEvents();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.token !== localStorage.getItem("token")) {
      // console.log("IN IFIF" );
      this.setState({
        token: localStorage.getItem("token"),
      });
    }
  }
  getEvents() {
    axios.get(globalData.apiUrl + "/api/event-types").then((response) => {
      this.setState({
        event: [...response.data.data],
        loading: false,
      });
      // console.log(this.state.event);
    });
  }
  render() {
    return (
      <>
        <footer className="page-footer footer-style-6 border-top-style">
          <div className="footer-bottom footer-bottom--bg">
            <div className="container d-flex justify-content-between">
              <div className="row">
                <div className="col-md-5">
                  <div className="footer-copyright text-left ">
                    <div className="">
                      <Link
                        href="#"
                        to={"/privacy-policy"}
                        className="terms mr-1"
                      >
                        Privacy & Cookies
                      </Link>
                      <Link
                        href="#"
                        to={"/terms-and-conditions"}
                        className="terms mx-1"
                      >
                        Terms & Conditions
                      </Link>
                      <a href="/sitemap.xml" className="mx-1">
                        Sitemap
                      </a>
                    </div>
                    <a href="#">©2024 Twynhams Tours</a>
                  </div>
                </div>
                <div className="col-md-3">
                  <Link
                    to={"/invoice"}
                    href="#"
                    id="loginButton"
                    className="py-1"
                  >
                    <img
                      src={`${globalData.baseUrl}front/images/pay-balance.png`}
                      alt="Pay Balance"
                    />
                  </Link>
                </div>
                <div className="col-md-4">
                  <div className="footer-copyright text-left d-flex align-items-center justify-content-md-end justify-content-between">
                    <div style={{ marginRight: "70px" }}>
                      <div className="">
                        <a href="#" style={{ fontSize: "20px" }}>
                          Follow Us
                        </a>
                      </div>
                      <a href="https://www.facebook.com/twynhamstours">
                        <i
                          className="icon-facebook mr-2"
                          style={{ fontSize: "20px" }}
                        ></i>
                      </a>
                      <a href="https://www.instagram.com/twynhamstours/">
                        <i
                          className="icon-instagram"
                          style={{ fontSize: "20px" }}
                        ></i>
                      </a>
                    </div>
                    <a href="#" className="">
                      <img
                        src={`${globalData.baseUrl}front/images/logo-2.png`}
                        alt="Logo"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div className="footer-sticky">
          <a
            className="back-to-top js-back-to-top compensate-for-scrollbar"
            href="#"
            title="Scroll To Top"
          >
            {" "}
            <i className="icon icon-angle-up"></i>{" "}
          </a>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cartCount: state.cart_count,
    userData: state.user_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCartCount: (count) => dispatch({ type: "CART_COUNT", value: count }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
