import React, { Component } from "react";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import Collapse from "react-bootstrap/Collapse";
import Swal from "sweetalert2";
import globalData from "../config.json";
import axios from "axios";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
//import { Helmet } from "react-helmet"; // Import Helmet for SEO
import SeoTag from "../includes/SeoTag";

class TermAndCondition extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      dataImages: [],
      errors: [],
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  loadScript() {
    window.scrollTo(0, 0);

    let scripts = [{ src: globalData.appUrl + "front/js/app-html.js" }];

    //Append the script element on each iteration

    scripts.forEach((item) => {
      const script = document.createElement("script");
      script.id = "myScript";
      script.src = item.src;

      document.body.appendChild(script);
    });

    const script = document.getElementById("myScript");
    if (script === undefined) {
      let scripts = [
        {
          src: globalData.apiUrl + "/front/js/app-html.js",
        },
      ];
      //Append the script element on each iteration

      scripts.forEach((item) => {
        const script = document.createElement("script");
        script.id = "myScript";
        script.src = item.src;
        script.async = true;
        document.body.appendChild(script);
      });
    } else {
      script.parentNode.removeChild(script);
      let scripts = [
        {
          src: globalData.apiUrl + "/front/js/app-html.js",
        },
      ];
      //Append the script element on each iteration
      scripts.forEach((item) => {
        const script = document.createElement("script");
        script.id = "myScript";
        script.src = item.src;
        script.async = true;
        document.body.appendChild(script);
      });
    }
  }
  componentDidMount() {
    // window.scrollTo(0, 0);

    // window.addEventListener("scroll", this.handleScroll);
    document.title = "Terms And Condition";
    ReactGA.initialize(globalData.googleAnalyticKey);
    ReactGA.pageview(window.location.pathname);
    ReactPixel.init(globalData.metaFacebookKey);
    ReactPixel.pageView(window.location.pathname);
    this.loadScript();
    this.getAboutus();
  }

  componentDidUpdate() {}

  componentWillUnmount() {}

  getAboutus() {
    axios
      .post(globalData.apiUrl + "/api/getCmsData", {
        slug: "terms-and-conditions",
      })
      .then((response) => {
        // console.log(response);
        this.setState({
          data: response.data.data,
          dataImages: [...response.data.dataImages],
        });
        // console.log(this.state.data);
      });
  }
  handleScroll() {
    // THEME.header.stickyHeaderInit(".hdr-content-sticky");
  }

  render() {
    return (
      <>
        {this.state.data && <SeoTag data={this.state.data} />}

        <div
          id="main-wrapper"
          className="tour-term-and-condition bg-dark for-padding-review"
        >
          {this.state.dataImages ? (
            <div className="slideshow">
              {this.state.dataImages.map((img) => (
                <img
                  key={img.id}
                  src={globalData.apiUrl + `/uploads/cms/${img.image}`}
                />
              ))}
            </div>
          ) : (
            ""
          )}
          {this.state.data ? (
            <div className="page-content">
              <div className="container pb-4 text-light">
                <h2 className="text-light">{this.state.data.title}</h2>
                {ReactHtmlParser(this.state.data.html)}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </>
    );
  }
}

export default TermAndCondition;
