import React, { Component } from "react";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import Collapse from "react-bootstrap/Collapse";
import Swal from "sweetalert2";
import globalData from "../config.json";
import { UncontrolledCollapse, Button, CardBody, Card } from 'reactstrap';
import Iframe from 'react-iframe';
import Loading from "./Loading";
import axios from "axios";
import ReactGA from 'react-ga';




class Outfleet extends Component {
  constructor(props) {
    super(props);
    this.state = {
        data: [],
        dataImages: [],
        errors: [],
      };


    this.handleScroll = this.handleScroll.bind(this);
  }

  loadScript() {
    let scripts = [{ src: globalData.apiUrl + "/front/js/app-html.js" }];

    //Append the script element on each iteration

    scripts.forEach((item) => {
      const script = document.createElement("script");
      script.id = "myScript";
      script.src = item.src;

      document.body.appendChild(script);
    });
  }
  componentDidMount() {
    // window.scrollTo(0, 0);
   
    // window.addEventListener("scroll", this.handleScroll);
    // ReactGA.initialize(globalData.googleAnalyticKey);
    // ReactGA.pageview(window.location.pathname);

    this.loadScript();
    this.getOurFleet();
  }

  getOurFleet() {

    axios.get(globalData.apiUrl + "/api/coach").then((response) => {
   
        this.setState({
          data: response.data.data,
        });
        //  console.log(this.state.data);
      });
  }



  componentDidUpdate() {}

  componentWillUnmount() {
    const script = document.getElementById("myScript");
    script.parentNode.removeChild(script);
  }

  handleScroll() {
    // THEME.header.stickyHeaderInit(".hdr-content-sticky");
  }
  
 

  render() {
    const data = this.state.data;
 
    return (
        <>
        <div className="page-content" id="our-fleet">
        { data.length > 0 ? 
        
            <div id="main-wrapper" className="bg-dark">
                { data.map((row, index) => {
                    
                    return (
                            <div key={row.id}>
                            <div key={row.id} className="for-bg-img" style={{ backgroundImage: `url(${row.mainImageUrl})`}}>
                                <div className="fleet-background w-ms-100">
                                    <h1 className="text-white mb-3 font-weight-bold mb-15">{ row.title }</h1>
                                    <a className="btn btn-custom home-button"  id={`toggler${row.idEncrypted}`} role="button" aria-expanded="false" aria-controls="collapseExample">
                                        View More
                                    </a>
                                </div>
                            </div>
                            <div  className="click-content bg-white">
                                <div className="container">
                                    <UncontrolledCollapse toggler={`#toggler${row.idEncrypted}`}>
                                        <div className="content-main-box">
                                            { row.coachGalleryDetail.length > 0 ?
                                            <section className="content-item" id="gallery">
                                                <div className="row">
                                                { row.coachGalleryDetail.map((res, index) => {
                                                    return (
                                                        <div key={index} className="col-xs-6 col-sm-4">
                                                        <img src={res.imageUrl} className="img-fluid for-boject-fit" alt="true"  />
                                                        </div>
                                                    )
                                                })}
                                                </div>
                                            </section>
                                            : ''
                                            }
                                            
                                            <div className=" bootstrap snippets bootdey">
                                                <section id="our-fleet-services" className="current">
                                                    <div className="services-top">
                                                    <div className="row text-center">
                                                        <div className="col-sm-12 col-md-12 col-md-12">
                                                        <h2 className="our-fleet-title-main">{ row.feature_title } <span></span></h2>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-offset-1 col-sm-12 col-md-12 col-md-10">
                                                        <div className="services-list">
                                                            <div className="row">
                                                                <div className="col-sm-12 col-md-12 col-md-12">
                                                                    { ReactHtmlParser(row.features) }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </section>
                                            </div>
                                            <div className=" bootstrap snippets bootdey">
                                                <section id="our-fleet-services" className="current">
                                                    <div className="services-top">
                                                    <div className="row text-center">
                                                        <div className="col-sm-12 col-md-12 col-md-12">
                                                        <h2 className="our-fleet-title-main">{ row.service_feature_title } <span></span></h2>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-offset-1 col-sm-12 col-md-12 col-md-10">
                                                        <div className="services-list">
                                                            <div className="row">
                                                                <div className="col-sm-12 col-md-12 col-md-12">
                                                                    { ReactHtmlParser(row.service_features) }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </section>
                                            </div>
                                            { row.service_seating_plan != null ?
                                            <div className=" bootstrap snippets bootdey">
                                                <section id="our-fleet-services" className="current">
                                                    <div className="services-top">
                                                        <div className="row text-center">
                                                            <div className="col-sm-12 col-md-12 col-md-12">
                                                            <h2 className="our-fleet-title-main">{ row.service_seating_plan_title }</h2>
                                                            </div>
                                                        </div>
                                                        <div className="row text-center">
                                                            <div className="col-sm-12 col-md-12 col-md-12">
                                                                <img src={row.serviceSeatingPlanUrl} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                            : ""
                                            }
                                            { row.service_virtual_tour != null ?
                                            <div className=" bootstrap snippets bootdey">
                                                <section id="our-fleet-services" className="current">
                                                    <div className="services-top">
                                                        <div className="row text-center">
                                                            <div className="col-sm-12 col-md-12 col-md-12">
                                                            <h2 className="our-fleet-title-main">{ row.service_virtual_tour_title } </h2>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-12 col-md-12 col-md-12">
                                                            { row.service_virtual_tour != '' ?
                                                                <Iframe url={`${row.service_virtual_tour}`}
                                                                width="100%"
                                                                height="500px"
                                                                id="myId"
                                                                className="myClassname"
                                                                display="initial"
                                                            position="relative"/>
                                                            : ''
                                                            }
                                                            
                                                            </div>
                                                        </div>

                                                    </div>
                                                </section>
                                            </div>
                                            : ''
                                            }
                                        </div>
                                    </UncontrolledCollapse>
                                </div>
                            </div>
                        </div>

                        );
                    })}
            
            </div>
           
            : <Loading />
        }
        </div>
        <div className="clearfix"></div>
        </>
           
      
        
       
    );
  }
}

export default Outfleet;
