import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import Home from "./Home";
// import Home2 from './Home_backup'
import Header from "./Header";
import Footer from "./Footer";
import Login from "./authuser/Login";
import Register from "./authuser/Register";
import ForgotPassword from "./authuser/ForgotPassword";
import ResetPassword from "./authuser/ResetPassword";
import Review from "./pages/Review";
import Contact from "./pages/Contact";
import AboutUs from "./pages/AboutUs";
import Outfleet from "./pages/Outfleet";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermAndCondition from "./pages/TermsAndCondition";
import Event from "./pages/Event";
import Eventdetail from "./pages/Eventdetail";
import ChildEventdetail from "./pages/ChildEventdetail";

import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import Myaccount from "./pages/Myaccount";
import OrderSuccess from "./pages/OrderSuccess";
import OrderProcess from "./pages/OrderProcess";
import Invoicedetail from "./pages/Invoicedetail";
import InvoiceOrderProcess from "./pages/InvoiceOrderProcess";

import InvoiceSucess from "./pages/InvoiceSucess";
import Invoice from "./pages/Invoice";

// import Checkout_r from './pages/Checkout_r'
import NotFound from "./pages/Notfound";

function Main(props) {
  return (
    <main>
      {/* <Header/> */}
      <Router>
        <Header />
        <Routes basename="/">
          <Route exact path="/" element={<Home />} />
          <Route
            exact
            path="/:token/:email_token/:user_token/:param"
            element={<Home />}
          />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/invoice" element={<Invoice />} />
          <Route exact path="/invoice-detail" element={<Invoicedetail />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/reset-password/:id" element={<ResetPassword />} />
          <Route exact path="/reviews" element={<Review />} />
          <Route exact path="/contact-us" element={<Contact />} />
          <Route exact path="/about-us" element={<AboutUs />} />
          {/* <Route exact path="/our-fleet" element={<Outfleet />} /> */}
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            exact
            path="/terms-and-conditions"
            element={<TermAndCondition />}
          />
          <Route exact path="/my-account" element={<Myaccount />} />
          <Route exact path="/:slug" element={<Event />} />
          <Route exact path="/:slug/:slug1" element={<Eventdetail />} />
          <Route
            exact
            path="/:slug/:slug1/:slug2"
            element={<ChildEventdetail />}
          />
          <Route exact path="/cart" element={<Cart />} />
          <Route exact path="/not-found" element={<NotFound />} />
          <Route
            exact
            path="/order/barclays/:string"
            element={<OrderProcess />}
          />
          <Route
            exact
            path="invoice/order/barclays/:string"
            element={<InvoiceOrderProcess />}
          />
          <Route
            exact
            path="invoice/order/invoice-sucess"
            element={<InvoiceSucess />}
          />
          <Route exact path="/order-success/:id" element={<OrderSuccess />} />

          <Route exact path="/checkout" element={<Checkout />} />
          <Route exact path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Router>
    </main>
  );
}

export default Main;
