import React, { Component } from "react";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import Collapse from "react-bootstrap/Collapse";
import Swal from "sweetalert2";
import axios from "axios";
import ReactGA from "react-ga";
import globalData from "../config.json";
import ReactPixel from "react-facebook-pixel";
//import { Helmet } from "react-helmet"; // Import Helmet for SEO
import SeoTag from "../includes/SeoTag";

class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      dataImages: [],
      errors: [],
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    // window.scrollTo(0, 0);
    let scripts = [{ src: globalData.apiUrl + "/front/js/app-html.js" }];

    //Append the script element on each iteration
    document.title = "Privacy Policy";
    scripts.forEach((item) => {
      const script = document.createElement("script");
      script.id = "myScript";
      script.src = item.src;
      script.async = true;
      document.body.appendChild(script);
    });
    // console.log(window.location.pathname);
    ReactGA.initialize(globalData.googleAnalyticKey);
    ReactGA.pageview(window.location.pathname);
    ReactPixel.init(globalData.metaFacebookKey);
    ReactPixel.pageView(window.location.pathname);
    // ReactGA.debug();

    // window.addEventListener("scroll", this.handleScroll);
    this.getPrivacyPolicy();
  }

  componentDidUpdate() {}

  componentWillUnmount() {}

  getPrivacyPolicy() {
    axios
      .post(globalData.apiUrl + "/api/getCmsData", {
        slug: "privacy-policy",
      })
      .then((response) => {
        // console.log(response);
        this.setState({
          data: response.data.data,
          dataImages: [...response.data.dataImages],
        });
        // console.log(this.state.data);
      });
  }
  handleScroll() {
    // THEME.header.stickyHeaderInit(".hdr-content-sticky");
  }

  render() {
    return (
      <>
        {this.state.data && <SeoTag data={this.state.data} />}

        <div
          id="main-wrapper"
          className="tour-privacy-policy bg-dark for-padding-review"
        >
          {this.state.dataImages ? (
            <div className="slideshow">
              {this.state.dataImages.map((img) => (
                <img
                  key={img.id}
                  src={globalData.apiUrl + `/uploads/cms/${img.image}`}
                />
              ))}
            </div>
          ) : (
            ""
          )}
          {this.state.data ? (
            <div className="page-content">
              <div className="container pb-4 text-light">
                <h2 className="text-light">{this.state.data.title}</h2>
                {ReactHtmlParser(this.state.data.html)}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </>
    );
  }
}

export default PrivacyPolicy;
