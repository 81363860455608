import { Helmet } from "react-helmet";

export default function SeoTag({ data }) {
  const canonicalUrl = window.location.href;
  return (
    <Helmet>
      <title>{data?.meta_title}</title>
      <link rel="canonical" href={canonicalUrl} />
      <meta name="description" content={data?.meta_description} />
      <meta name="keywords" content={data?.meta_keywords} />
      <meta property="og:title" content={data?.meta_title} />
      <meta property="og:description" content={data?.description} />
      {/* <meta property="og:image" content={data.image} /> */}
    </Helmet>
  );
}
