import React, { Component } from "react";
import { Link, useParams } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import globalData from "./config.json";
import axios from "axios";
import Swal from "sweetalert2";
//import { Helmet } from "react-helmet"; // Import Helmet for SEO
// import { BoxLoading } from "react-loadingg";
// import Loading from "./pages/Loading";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import SeoTag from "./includes/SeoTag";
export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />;
  };
}

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      banners: [],
      data: [],
      autoIndex: 0,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }
  loadScript = () => {
    let scripts = [{ src: globalData.appUrl + "front/js/app-html.js" }];

    //Append the script element on each iteration
    // console.log(" Contact Loadscript)", scripts);
    scripts.forEach((item) => {
      const script = document.createElement("script");
      script.id = "myScript";
      script.src = item.src;

      document.body.appendChild(script);
    });

    const script = document.getElementById("myScript");
    if (script === undefined) {
      let scripts = [
        {
          src: globalData.apiUrl + "/front/js/app-html.js",
        },
      ];
      //Append the script element on each iteration

      scripts.forEach((item) => {
        const script = document.createElement("script");
        script.id = "myScript";
        script.src = item.src;
        script.async = true;
        document.body.appendChild(script);
      });
    } else {
      script.parentNode.removeChild(script);
      let scripts = [
        {
          src: globalData.apiUrl + "/front/js/app-html.js",
        },
      ];
      //Append the script element on each iteration
      scripts.forEach((item) => {
        const script = document.createElement("script");
        script.id = "myScript";
        script.src = item.src;
        script.async = true;
        document.body.appendChild(script);
      });
    }
  };
  async componentDidMount() {
    window.scrollTo(0, 0);

    // ReactGA.initialize(globalData.googleAnalyticKey);
    // ReactGA.pageview(window.location.pathname);
    document.title = "Twynhams Tours";
    ReactPixel.init(globalData.metaFacebookKey);
    ReactPixel.pageView(window.location.pathname);
    if (
      this.props.match.params.token !== undefined &&
      this.props.match.params.email_token !== undefined &&
      this.props.match.params.user_token !== undefined
    ) {
      this.sendEmailVerify();
    }
    const getIndex = localStorage.getItem("autoIndex");

    if (getIndex === null) {
      // If autoIndex is not in local storage, set it to the initial state
      localStorage.setItem("autoIndex", this.state.autoIndex);
    } else {
      // Parse the stored autoIndex as an integer
      const parsedIndex = parseInt(getIndex, 10);

      this.setState({
        autoIndex: parsedIndex,
      });

      // Update the autoIndex and store it in local storage

      const updatedIndex = parsedIndex < 2 ? parsedIndex + 1 : 0;
      localStorage.setItem("autoIndex", updatedIndex.toString());
    }
    await this.getBanners();
    //this.loadScript()
    // window.addEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate() {
    //this.loadScript();
    /* const script = document.getElementById("myScript");
    if (script == undefined) {
      let scripts = [
        {
          src: globalData.apiUrl + "/front/js/app-html.js",
        },
      ];
      //Append the script element on each iteration

      scripts.forEach((item) => {
        const script = document.createElement("script");
        script.id = "myScript";
        script.src = item.src;
        script.async = true;
        document.body.appendChild(script);
      });
    } else {
      script.parentNode.removeChild(script);
      let scripts = [
        {
          src: globalData.apiUrl + "/front/js/app-html.js",
        },
      ];
      //Append the script element on each iteration
      scripts.forEach((item) => {
        const script = document.createElement("script");
        script.id = "myScript";
        script.src = item.src;
        script.async = true;
        document.body.appendChild(script);
      });
    } */
    setTimeout(() => {
      // alert("IN");
      //  this.loadScript();
    }, 2000);
    this.loadScript();
  }

  componentWillUnmount() {
    // console.log('handleBeforeUnload');
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
    // Save the current auto index in local storage when the component unmounts
  }

  handleBeforeUnload = (event) => {
    // Check if the user is trying to refresh the page
    // console.log('handleBeforeUnload');
    if (
      event.currentTarget.performance.navigation.type ===
      PerformanceNavigation.TYPE_RELOAD
    ) {
      // The user is refreshing the page
      // console.log(this.state.autoIndex);
      localStorage.setItem("autoIndex", this.state.autoIndex);
      // You can perform actions here if needed
    }
  };
  sendEmailVerify() {
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .post(
        globalData.apiUrl + "/api/user/email-verify",
        {
          token: this.props.match.params.token,
          emailToken: this.props.match.params.email_token,
          userToken: this.props.match.params.user_token,
        },
        config
      )
      .then((response) => {
        Swal.fire(response.data.message, "", "success");
      })
      .catch((err) =>
        Swal.fire({
          title: "Error!",
          text: err.response.data.message,
          icon: "error",
        })
      );
  }

  getBanners() {
    axios.get(globalData.apiUrl + "/api/banners").then((response) => {
      this.setState({
        banners: [...response.data.data.banners],
        data: response.data.data.cms,
        loading: false,
      });
    });
  }

  handleSelect = (index) => {
    // Update the auto index when the user manually changes the slide
    this.setState({ autoIndex: index });

    localStorage.setItem("autoIndex", index.toString());
  };

  customArrow = (onClickHandler, isPrev) =>
    isPrev ? (
      <button
        onClick={onClickHandler}
        type="button"
        aria-label="previous slide / item"
        className="control-arrow control-prev"
      >
        <i aria-hidden="true" className="fa fa-angle-left"></i>
      </button>
    ) : (
      <button
        onClick={onClickHandler}
        type="button"
        aria-label="next slide / item"
        className="control-arrow control-next"
      >
        <i aria-hidden="true" className="fa fa-angle-right"></i>
      </button>
    );

  render() {
    const { autoIndex } = this.state;

    return (
      <div className="page-content" key={1}>
        {this.state.data && (
          <>
            {/* <Helmet>
              <title>{this.state.data.meta_title}</title>
              <link rel="canonical" href={canonicalUrl} />
              <meta name="title" content={this.state.data.meta_title} />
              <meta
                name="description"
                content={this.state.data.meta_description}
              />
              <meta name="keywords" content={this.state.data.meta_keywords} />
            </Helmet> */}
            <SeoTag data={this.state.data} />
          </>
        )}
        <div className="holder fullwidth full-nopad mt-0">
          <div className="container ">
            {this.state.banners.length > 0 ? (
              <Carousel
                autoIndex
                dynamicHeight={true}
                swipeable={false}
                infiniteLoop={true}
                showArrows={true}
                showThumbs={false}
                selectedItem={autoIndex}
                onChange={this.handleSelect}
                renderArrowPrev={(onClickHandler) =>
                  this.customArrow(onClickHandler, true)
                }
                renderArrowNext={(onClickHandler) =>
                  this.customArrow(onClickHandler, false)
                }
              >
                {this.state.banners.map((ban) => (
                  <div className="bnslider-slide1" key={ban.id}>
                    <img
                      className="lazyload"
                      src={`${globalData.apiUrl}/uploads/banners/${ban.image}`}
                      alt={ban.title}
                    />
                    <div className="bnslider-text-wrap bnslider-overlay text-white home-page-slider-button-mobile-css">
                      <div className="bnslider-text-content txt-middle txt-center txt-middle-m txt-center-m ">
                        <div className="bnslider-text-content-flex ">
                          <div className="bnslider-vert motorsport_tours_only w-ms-100">
                            <div className="text-content-slider w-s-75 ">
                              <div
                                className="bnslider-text order-1 mt-0 bnslider-text--md text-center hide-mobile data-ini heading  cssanimation sequence fadeInBottom"
                                data-animation="fadeInUp"
                                data-animation-delay="800"
                                data-fontcolor="#fff"
                                data-fontweight="700"
                                data-fontline="1.2"
                              >
                                <span> {ban.title} </span>
                              </div>
                              <div
                                className="bnslider-text order-2 mt-sm bnslider-text--xs text-center data-ini cssanimation sequence fadeInBottom"
                                data-animation="fadeInUp"
                                data-animation-delay="1000"
                                data-fontcolor="#fff"
                                data-fontweight="400"
                                data-fontline="1.5"
                              >
                                <span>{ReactHtmlParser(ban.description)}</span>
                              </div>
                              <div
                                className="btn-wrap text-center order-4 mt-2 mt-md cssanimation sequence fadeInBottom"
                                data-animation="fadeIn"
                                data-animation-delay="2000"
                                style={{ opacity: "1" }}
                              >
                                <span>
                                  <Link
                                    to={`/${ban.handle}`}
                                    className="btn btn-custom home-button css for-tablet"
                                  >
                                    {ReactHtmlParser(ban.button_text)}
                                  </Link>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Carousel>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }

  handleScroll() {
    // THEME.header.stickyHeaderInit(".hdr-content-sticky");
  }
}

export default withRouter(Home);
